import React from "react";
import HeroSection from "./../components/HeroSection";
import AboutUs from "./../components/AboutUs";
import OurServices from "./../components/OurServices";
import OurProcess from "./../components/OurProcess";
import MyCTA from "./../components/CTA";
import GetAQuote from "./../components/GetAQuote";
import OurWork from "./../components/OurWork";

import { useRouter } from "./../util/router.js";

function IndexPage(props) {
  const router = useRouter();

  return (
    <>
      <HeroSection
        color="white"
        size="medium"
        title="Your landing page title here"
        subtitle="This landing page is perfect for showing off your awesome product and driving people to sign up for a paid plan."
        buttonText="Get Started"
        image="https://uploads.divjoy.com/undraw-japan_ubgk.svg"
        buttonOnClick={() => {
          router.push("/pricing");
        }}
      />
      <AboutUs />
      <OurServices />
      <OurProcess />
      <MyCTA />
      <GetAQuote />
      <OurWork />
      
    </>
  );
}

export default IndexPage;
