import React from "react";
import Section from "./Section";
import GallerySec from "./GallerySec";


function OurWork(props) {
  return (
    <Section id="gallery" className="ttm-row portfolio-section bg-img2 clearfix" color={props.color} size={props.size}>
        <div class="container">
                
                <div class="row">
                    <div class="col-lg-12">
                        <div class="section-title width-36">
                            <div class="title-header">
                                <h5>RECENTLY COMPLETED</h5>
                                <h2 class="title">Our Latest Projects</h2>
                            </div>
                        </div>
                        <GallerySec 
                            images = {[
                                {
                                src: require('../images/blog/service1.jpg'),
                                thumbnail: require('../images/blog/service1.jpg'),
                                
                                },
                                {
                                src: require('../images/blog/service2.jpg'),
                                thumbnail: require('../images/blog/service2.jpg'), 
                                
                                },
                        
                                {
                                src: require('../images/blog/service3.jpg'),
                                thumbnail: require('../images/blog/service3.jpg'), 
                                    
                                },
                                {
                                src: require('../images/blog/service4.jpg'),
                                thumbnail: require('../images/blog/service4.jpg'), 
                                    
                                },
                                {
                                src: require('../images/blog/service5.jpg'),
                                thumbnail: require('../images/blog/service5.jpg'), 
                                    
                                },
                                {
                                src: require('../images/blog/service6.jpg'),
                                thumbnail: require('../images/blog/service6.jpg'), 
                                    
                                }
                            ]}

                        />,
                    </div>
                </div>
            </div>
    </Section>
  );
}

export default OurWork;
