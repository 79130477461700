import React from "react";
import Section from "./Section";
import { Link } from "./../util/router.js";
import "./Footer.scss";

function Footer(props) {
  return (
    <Section className="footer widget-footer clearfix" color={props.color} size={props.size}>
      <div class="second-footer ttm-textcolor-white">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-12 widget-area">
                        <div class="widget clearfix">
                            <div class="footer-logo">
                                <img id="footer-logo-img" class="img-center" src={require('../images/logo-light.png')} alt="" />
                            </div>
                            <div class="cal-lic">
                              <h6>California Contractor LIC <span>#1001561</span></h6>
                            </div>
                        </div>
                        <div class="widget widget_text clearfix">
                            <div class="footer-links">
                              <ul>
                                <li><a href="#services">Doors & Windows</a></li>
                                <li><a href="#services">Replacement Windows</a></li>
                                <li><a href="#services">Patio & French Doors</a></li>
                                <li><a href="#services">Showers</a></li>
                                <li><a href="#services">Glass Pool Fences</a></li>
                              </ul>
                            </div>
                            <div class="footer-links">
                              <ul>
                                <li><a href="#services">Mirrors</a></li>
                                <li><a href="#services">Store Front Glass</a></li>
                                <li><a href="#services">Window Glass</a></li>
                                <li><a href="#services">Commercial Windows</a></li>
                                <li><a href="#services">Mirror Walls</a></li>
                                <li><a href="#services">Glass Office Walls</a></li>
                              </ul>
                            </div>
                        </div>
                        <div class="widget widget_text clearfix">
                            <div class="footer-call-links">
                              <ul>
                                <li><a href="tel:408-469-4477">408-469-4477</a></li>
                                <li><a href="tel:510-400-7788">510-400-7788</a></li>
                                <li><a href="tel:415-901-6600">415-901-6600</a></li>
                                <li><a href="tel:650-481-8844">650-481-8844</a></li>
                                <li><a href="tel:925-448-3200">925-448-3200</a></li>
                              </ul>
                            </div>
                        </div>
                        
                    </div>
                    {/* <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 widget-area">
                        <div class="widget widget_nav_menu clearfix">
                           <h3 class="widget-title">Our Services</h3>
                            <ul id="menu-footer-services">
                                <li><a href="services-1.html">Doors & Windows</a></li>
                                <li><a href="about-1.html">Replacement Windows</a></li>
                                <li><a href="portfolio-style-1.html">Patio & French Doors</a></li>
                                <li><a href="blog.html">Showers</a></li>
                                <li><a href="our-team.html">Glass Pool Fences</a></li>
                                <li><a href="faq.html">Mirrors</a></li>
                                <li><a href="career.html">Store Front Glass</a></li>
                                <li><a href="contact-us-1.html">Window Glass</a></li>
                                <li><a href="single-style-3.html">Commercial Windows</a></li>
                                <li><a href="contact-us-2.html">Mirror Walls</a></li>
                                <li><a href="home-shop.html">Glass Office Walls</a></li>
                            </ul>
                        </div>
                    </div> */}
                    {/* <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 widget-area">
                        <div class="widget widget_text clearfix">
                           <h3 class="widget-title">Call Us Now</h3>
                           <div class="textwidget widget-text">
                                <h3 class="ttm-textcolor-skincolor"><a href="tel:408-469-4477">408-469-4477</a></h3>
                                <h3 class="ttm-textcolor-skincolor"><a href="tel:510-400-7788">510-400-7788</a></h3>
                                <h3 class="ttm-textcolor-skincolor"><a href="tel:415-901-6600">415-901-6600</a></h3>
                                <h3 class="ttm-textcolor-skincolor"><a href="tel:650-481-8844">650-481-8844</a></h3>
                                <h3 class="ttm-textcolor-skincolor"><a href="tel:925-448-3200">925-448-3200</a></h3>
                                
                                <a class="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-border ttm-btn-color-white mt-15 mb-20" href="#">REQUEST WITH ONLINE FORM</a>
                            </div>
                        </div>
                    </div> */}
                    
                </div>
            </div>
        </div>
        <div class="bottom-footer-text ttm-textcolor-white">
            <div class="container">
                <div class="row copyright">
                    <div class="col-md-8 ttm-footer2-left">
                        <span>Copyright © 2020&nbsp;<a href="#">Roxy Glass Company</a>. All rights reserved. Powered by <a href="https://embtelsolutions.com/?utm_source=footer&utm_medium=website&utm_campaign=roxyglass">Embtel Solutions, Inc.</a></span>
                    </div>
                    <div class="col-md-4 ttm-footer2-right">
                       <div class="social-icons">
                            <ul class="list-inline">
                                <li><a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                                <li><a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                                <li><a href="#"><i class="fa fa-flickr" aria-hidden="true"></i></a></li>
                                <li><a href="#"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      {/* <div className="FooterComponent__container container">
        <div className="brand left">
          <Link to="/">
            <img src={props.logo} alt="Logo" />
          </Link>
        </div>
        <div className="links right">
          <Link to="/about">About</Link>
          <Link to="/faq">FAQ</Link>
          <Link to="/contact">Contact</Link>
          <a
            target="_blank"
            href="https://medium.com"
            rel="noopener noreferrer"
          >
            Blog
          </a>
        </div>
        <div className="social right">
          <a
            href="https://twitter.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="icon">
              <i className="fab fa-twitter" />
            </span>
          </a>
          <a
            href="https://facebook.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="icon">
              <i className="fab fa-facebook-f" />
            </span>
          </a>
          <a
            href="https://instagram.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="icon">
              <i className="fab fa-instagram" />
            </span>
          </a>
        </div>
        <div className="copyright left">{props.copyright}</div>
      </div> */}
    </Section>
  );
}

export default Footer;
