import React, { useState } from "react";
import NavbarContainer from "./NavbarContainer";
import { Link } from "./../util/router.js";
import { useAuth } from "./../util/auth.js";

function Navbar(props) {
  const auth = useAuth();
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    
    <NavbarContainer spaced={props.spaced} color={props.color}>
          <ul className="dropdown">
              <li className="active"><a href="/">Home</a></li>
              <li><a href="#about">About</a></li>
              <li><a href="#services">Residential</a></li>
              <li><a href="#services">Commercial</a></li>
              <li><a href="#gallery">Gallery</a></li>
          </ul>
          
    </NavbarContainer>
  );
}

export default Navbar;
