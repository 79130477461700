import React from "react";
import Section from "./Section";


function GetAQuote(props) {
  return (
    <Section className="ttm-row contact-section bg-layer clearfix" color={props.color} size={props.size}>
        <div class="container">
                <div class="row mt_100 box-shadow2 res-991-mt-0">
                    <div class="col-lg-7">
                        <div class="spacing-5 ttm-bgcolor-white">
                            
                            <div class="section-title clearfix">
                                <div class="title-header">
                                    <h5>FREE CONSULTATION</h5>
                                    <h2 class="title">Get a Free Quote</h2>
                                </div>
                            </div>
                            <form id="ttm-quote-form" class="ttm-quote-form wrap-form clearfix" method="POST" action="https://getform.io/f/86fc0e73-69ad-4021-9215-d7bb3bd3253c">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <label>
                                            <span class="text-input"><input name="name" type="text" placeholder="Full Name*" required="required" /></span>
                                        </label>
                                    </div>
                                    <div class="col-lg-6">
                                        <label>
                                            <span class="text-input"><input name="email" type="text" placeholder="Email Address*" required="required" /></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <label>
                                            <span class="text-input"><input name="phone" type="text" placeholder="Phone Number*" required="required" /></span>
                                        </label>
                                    </div>
                                    <div class="col-lg-6">
                                        <label>
                                            <span class="text-input"><input name="venue" type="text" placeholder="Zip Code*" required="required" /></span>
                                        </label>
                                    </div>
                                </div>
                                <label>
                                    <span class="text-input">
                                        <select name="services"><option value="">Services</option>
                                                <option value="residential">Residential</option>
                                                <option value="commercial">Commercial</option>
                                        </select>
                                    </span>
                                </label>
                                <label>
                                    <span class="text-input"><textarea name="message" rows="4" placeholder="Your Message" ></textarea></span>
                                </label>
                                <input name="submit" type="submit" id="submit" class="submit" value="GET A FREE QUOTE" />
                            </form>
                        </div>
                    </div>
                    <div class="col-lg-5">
                        
                        <div class="col-bg-img-three ttm-bg ttm-col-bgimage-yes spacing-4">
                            <div class="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                            <div class="layer-content">
                                <div class="row-title">
                                    
                                    <div class="section-title ttm-textcolor-white clearfix">
                                        <h2 class="title ttm-textcolor-skincolor">CALL OUR</h2>
                                        <h2 class="title">TOLL FREE</h2>
                                        <h2 class="title ttm-textcolor-skincolor">NUMBER</h2>
                                    </div>
                                    <a class="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-icon-btn-right ttm-btn-style-border ttm-btn-color-white mt-30" href="844-600-3960">CALL US NOW! <i class="ti ti-angle-double-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </Section>
  );
}

export default GetAQuote;
