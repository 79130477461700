import React from "react";
import Section from "./Section";


function OurServices(props) {
  return (
    <Section id="services" className="ttm-row services2-section home4-services2-section bg-img4 clearfix" color={props.color} size={props.size}>
        <div className="container">
                
                <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-8">
                        
                        <div className="section-title with-desc title-style-center_text clearfix">
                            <div className="title-header">
                                <h5>OUR SERVICES</h5>
                                <h2 className="title">How Can We Help You?</h2>
                            </div>
                            <div className="title-desc mb-40">Whether you are a home owner with just a broken window, or a contractor with a glass-front multi-million dollar high-rise project, or maybe you are just thinking about a new patio door or mirror, you have come to the right place.</div>
                        </div>
                    </div>
                    <div className="col-md-2"></div>
                </div>
                
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ttm-tabs ttm-tab-style-classic">
                            <ul className="tabs mb-25 res-1199-mb-20">
                                <li className="tab active"><a href="#"> <i className="flaticon flaticon-faucet-1"></i> Residential Services</a></li>
                                <li className="tab"><a href="#"> <i className="flaticon flaticon-man-painting-wall"></i>Commercial Services</a></li>
                            </ul>
                            <div className="content-tab width-100">
                                
                                <div className="content-inner active">
                                    <div className="row">
                                        <div className="col-md-4">
                                            
                                            <div className="featured-imagebox mb-30">
                                                <div className="featured-thumbnail">
                                                    <img className="img-fluid" src={require('../images/blog/service1.jpg')} alt="" />
                                                </div>
                                                <div className="ttm-box-bottom-content">
                                                    <div className="featured-title featured-title">
                                                        <h5><a href="tel:8446003960" tabindex="-1">Doors & Windows</a></h5>
                                                    </div>
                                                    <div className="featured-desc">
                                                        <p>We have provided superior glass products and services to the greater Bay area for over 40 years.</p>
                                                        <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-icon-btn-right ttm-btn-style-border ttm-btn-color-black mt-15" href="tel:8446003960">CALL US NOW!<i className="ti ti-angle-double-right"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div className="col-md-4">
                                            
                                            <div className="featured-imagebox mb-30">
                                                <div className="featured-thumbnail">
                                                    <img className="img-fluid" src={require('../images/blog/service2.jpg')} alt="" />
                                                </div>
                                                <div className="ttm-box-bottom-content">
                                                    <div className="featured-title featured-title">
                                                        <h5><a href="tel:8446003960" tabindex="-1">Replacement Windows</a></h5>
                                                    </div>
                                                    <div className="featured-desc">
                                                        <p>We also have aluminum frame windows and doors in a wide variety of types and styles.</p>
                                                        <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-icon-btn-right ttm-btn-style-border ttm-btn-color-black mt-15" href="tel:8446003960">CALL US NOW!<i className="ti ti-angle-double-right"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div className="col-md-4">
                                            
                                            <div className="featured-imagebox mb-30">
                                                <div className="featured-thumbnail">
                                                    <img className="img-fluid" src={require('../images/blog/service3.jpg')} alt="" />
                                                </div>
                                                <div className="ttm-box-bottom-content">
                                                    <div className="featured-title featured-title">
                                                        <h5><a href="tel:8446003960" tabindex="-1">Patio & French Doors</a></h5>
                                                    </div>
                                                    <div className="featured-desc">
                                                        <p>French doors are not only functional, but they also add to the aesthetics of any room they’re fitted in. </p>
                                                        <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-icon-btn-right ttm-btn-style-border ttm-btn-color-black mt-15" href="tel:8446003960">CALL US NOW!<i className="ti ti-angle-double-right"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div className="col-md-4">
                                            
                                            <div className="featured-imagebox mb-30">
                                                <div className="featured-thumbnail">
                                                    <img className="img-fluid" src={require('../images/blog/service4.jpg')} alt="" />
                                                </div>
                                                <div className="ttm-box-bottom-content">
                                                    <div className="featured-title featured-title">
                                                        <h5><a href="tel:8446003960" tabindex="-1">Showers</a></h5>
                                                    </div>
                                                    <div className="featured-desc">
                                                        <p>For most home-owners, a bathroom remodel with frameless shower doors and enclosures is a major project.</p>
                                                        <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-icon-btn-right ttm-btn-style-border ttm-btn-color-black mt-15" href="tel:8446003960">CALL US NOW!<i className="ti ti-angle-double-right"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div className="col-md-4">
                                            
                                            <div className="featured-imagebox mb-30">
                                                <div className="featured-thumbnail">
                                                    <img className="img-fluid" src={require('../images/blog/service5.jpg')} alt="" />
                                                </div>
                                                <div className="ttm-box-bottom-content">
                                                    <div className="featured-title featured-title">
                                                        <h5><a href="tel:8446003960" tabindex="-1">Glass Pool Fences</a></h5>
                                                    </div>
                                                    <div className="featured-desc">
                                                        <p>Swimming pool glass fences provide maximum safety and security for your home without compromising your view.</p>
                                                        <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-icon-btn-right ttm-btn-style-border ttm-btn-color-black mt-15" href="tel:8446003960">CALL US NOW!<i className="ti ti-angle-double-right"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div className="col-md-4">
                                            
                                            <div className="featured-imagebox mb-30">
                                                <div className="featured-thumbnail">
                                                    <img className="img-fluid" src={require('../images/blog/service6.jpg')} alt="" />
                                                </div>
                                                <div className="ttm-box-bottom-content">
                                                    <div className="featured-title featured-title">
                                                        <h5><a href="tel:8446003960" tabindex="-1">Mirrors</a></h5>
                                                    </div>
                                                    <div className="featured-desc">
                                                        <p>We provide quality mirror glass for homeowner looking for quality materials and expert installation.</p>
                                                        <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-icon-btn-right ttm-btn-style-border ttm-btn-color-black mt-15" href="tel:8446003960">CALL US NOW!<i className="ti ti-angle-double-right"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="content-inner active">
                                    <div className="row justify-content-center">
                                        <div className="col-md-4">
                                            
                                            <div className="featured-imagebox mb-30">
                                                <div className="featured-thumbnail">
                                                    <img className="img-fluid" src={require('../images/blog/service7.jpg')} alt="" />
                                                </div>
                                                <div className="ttm-box-bottom-content">
                                                    <div className="featured-title featured-title">
                                                        <h5><a href="tel:8446003960" tabindex="-1">Store Front Glass</a></h5>
                                                    </div>
                                                    <div className="featured-desc">
                                                        <p>Providing the best commercial glass products for more than 40 years, we have amassed a truly impressive portfolio</p>
                                                        <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-icon-btn-right ttm-btn-style-border ttm-btn-color-black mt-15" href="tel:8446003960">CALL US NOW!<i className="ti ti-angle-double-right"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div className="col-md-4">
                                            
                                            <div className="featured-imagebox mb-30">
                                                <div className="featured-thumbnail">
                                                    <img className="img-fluid" src={require('../images/blog/service2.jpg')} alt="" />
                                                </div>
                                                <div className="ttm-box-bottom-content">
                                                    <div className="featured-title featured-title">
                                                        <h5><a href="tel:8446003960" tabindex="-1">Window Glass</a></h5>
                                                    </div>
                                                    <div className="featured-desc">
                                                        <p>Replacing existing window glass? or is it a new installation? You are at right place, our company does it all.</p>
                                                        <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-icon-btn-right ttm-btn-style-border ttm-btn-color-black mt-15" href="tel:8446003960">CALL US NOW!<i className="ti ti-angle-double-right"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div className="col-md-4">
                                            
                                            <div className="featured-imagebox mb-30">
                                                <div className="featured-thumbnail">
                                                    <img className="img-fluid" src={require('../images/blog/service8.jpg')} alt="" />
                                                </div>
                                                <div className="ttm-box-bottom-content">
                                                    <div className="featured-title featured-title">
                                                        <h5><a href="tel:8446003960" tabindex="-1">Commercial Windows</a></h5>
                                                    </div>
                                                    <div className="featured-desc">
                                                        <p>The best laid architectural plans need the best quality glass and most technologically advanced installation.</p>
                                                        <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-icon-btn-right ttm-btn-style-border ttm-btn-color-black mt-15" href="tel:8446003960">CALL US NOW!<i className="ti ti-angle-double-right"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div className="col-md-4">
                                            
                                            <div className="featured-imagebox mb-30">
                                                <div className="featured-thumbnail">
                                                    <img className="img-fluid" src={require('../images/blog/service9.jpg')} alt="" />
                                                </div>
                                                <div className="ttm-box-bottom-content">
                                                    <div className="featured-title featured-title">
                                                        <h5><a href="tel:8446003960" tabindex="-1">Safety Tempered Glass</a></h5>
                                                    </div>
                                                    <div className="featured-desc">
                                                        <p>At Roxy Glass, we offer a wide array of top quality commercial glass services in Bay Area and nearby areas.</p>
                                                        <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-icon-btn-right ttm-btn-style-border ttm-btn-color-black mt-15" href="tel:8446003960">CALL US NOW!<i className="ti ti-angle-double-right"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div className="col-md-4">
                                            
                                            <div className="featured-imagebox mb-30">
                                                <div className="featured-thumbnail">
                                                    <img className="img-fluid" src={require('../images/blog/service6.jpg')} alt="" />
                                                </div>
                                                <div className="ttm-box-bottom-content">
                                                    <div className="featured-title featured-title">
                                                        <h5><a href="tel:8446003960" tabindex="-1">Mirror Walls</a></h5>
                                                    </div>
                                                    <div className="featured-desc">
                                                        <p>Looking for a glass repair company. We'll manage repairing existing glass, new installation and maintainance.</p>
                                                        <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-icon-btn-right ttm-btn-style-border ttm-btn-color-black mt-15" href="tel:8446003960">CALL US NOW!<i className="ti ti-angle-double-right"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div className="col-md-4">
                                            
                                            <div className="featured-imagebox mb-30">
                                                <div className="featured-thumbnail">
                                                    <img className="img-fluid" src={require('../images/blog/service10.jpg')} alt="" />
                                                </div>
                                                <div className="ttm-box-bottom-content">
                                                    <div className="featured-title featured-title">
                                                        <h5><a href="tel:8446003960" tabindex="-1">Glass Office Walls</a></h5>
                                                    </div>
                                                    <div className="featured-desc">
                                                        <p>We can provide board-up services, storefront replacement, glass repair, installation and a lot more.</p>
                                                        <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-icon-btn-right ttm-btn-style-border ttm-btn-color-black mt-15" href="tel:8446003960">CALL US NOW!<i className="ti ti-angle-double-right"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </Section>
  );
}

export default OurServices;
