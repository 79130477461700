import React from "react";
import Section from "./Section";


function MyCTA(props) {
  return (
    <Section id="get-a-quote" className="ttm-row row-title-section bg-img5 clearfix" color={props.color} size={props.size}>
        <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="ttm-content-box text-center">
                            {/* <div class="ttm-video-btn">
                                <a class="ttm-play-btn ttm_prettyphoto" href="https://youtu.be/MdLVzXf7v5E">
                                    <span class="ttm-btn-play"><i class="fa fa-play"></i></span>
                                </a>
                            </div> */}
                            <div class="row-title mt-25">
                                
                                <div class="section-title ttm-textcolor-white clearfix">
                                    <h5>Don't Know What To Start With?</h5>
                                    <h2 class="title">Get A Solutions For All<br/>Home Services</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </Section>
  );
}

export default MyCTA;
