import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import SectionButton from "./SectionButton";
import "./HeroSection.scss";

function HeroSection(props) {
  return (
    <Section color={props.color} size={props.size}>
      <div className="main-slider-section">
      <div className="container">
        <div className="row row-eq-height justify-content-between align-items-center" >
          <div className="col-md-7">
            <div className="sub-title">
              <p>Providing Quality Glass</p>
            </div>
            <div className="main-title">
              <h1><span>Lowest</span> Prices in the Bay Area!</h1>
            </div>
            <div className="description">
              <ul>
                <li>Licensed</li>
                <li>Bonded</li>
                <li>Insured</li>
              </ul>
            </div>
            <div className="main-button">
              <a href="tel:8446003960">844-600-3960</a>
            </div>
          </div>
          <div className="col-md-4">
              <div className="slider-form ttm-rounded-shadow-box box-shadow2">
                  <div class="section-title with-desc title-style-center_text clearfix">
                      <div class="title-header">
                          <h5>CONTACT US TODAY FOR</h5>
                          <h2 class="title">Free Estimate!</h2>
                      </div>
                      {/* <div class="title-desc">Raising a heavy fur muff that covered the whole of her lower arm towards the viewer regor then turned to look out the window</div> */}
                  </div>
                  <form id="ttm-contactform" class="ttm-contactform wrap-form clearfix" method="POST" action="https://getform.io/f/86fc0e73-69ad-4021-9215-d7bb3bd3253c">
                      <div class="row">
                          <div class="col-lg-12">
                              <label>
                                  <span class="text-input"><i class="ttm-textcolor-skincolor ti-user"></i><input name="name" type="text" placeholder="Your Name*" required="required" /></span>
                              </label>
                          </div>
                          <div class="col-lg-12">
                              <label>
                                  <span class="text-input"><i class="ttm-textcolor-skincolor ti-mobile"></i><input name="phone" type="text" placeholder="Phone Number*" required="required" /></span>
                              </label>
                          </div>
                      </div>
                      <div class="row">
                          <div class="col-lg-12">
                              <label>
                                  <span class="text-input"><i class="ttm-textcolor-skincolor ti-email"></i><input name="email" type="email" placeholder="Email Address*" required="required" /></span>
                              </label>
                          </div>
                          <div class="col-lg-12">
                              <label>
                                  <span class="text-input"><i class="ttm-textcolor-skincolor ti-location-pin"></i><input name="venue" type="text" placeholder="Zip Code*" required="required" /></span>
                              </label>
                          </div>
                      </div>
                      <label>
                          <span class="text-input"><i class="ttm-textcolor-skincolor ti-comment"></i><textarea name="message" cols="40" placeholder="Message"></textarea></span>
                      </label>
                      <input name="submit" type="submit" id="submit" class="slider-btn" value="BOOK NOW!" />
                  </form>
              </div>
          </div>
        </div>
        {/* <div className="columns is-vcentered is-desktop">
          <div className="column is-5-desktop has-text-centered-touch">
            <SectionHeader
              title={props.title}
              subtitle={props.subtitle}
              size={1}
            />
            <SectionButton
              parentColor={props.color}
              size="medium"
              onClick={props.buttonOnClick}
            >
              {props.buttonText}
            </SectionButton>
          </div>
          <div className="column is-1" />
          <div className="column">
            <figure className="HeroSection__image image">
              <img src={props.image} alt="Illustration" />
            </figure>
          </div>
        </div> */}
      </div>
      </div>
    </Section>
  );
}

export default HeroSection;
