import React from "react";
import Section from "./Section";


function AboutUs(props) {
  return (
    <Section id="about" className="ttm-row about-section ttm-bgcolor-white bg-img1 break-991-colum clearfix" color={props.color} size={props.size}>
        <div class="container">
                <div class="row">
                    <div class="col-md-5 col-sm-12">
                        
                        <div class="ttm_single_image-wrapper mb-35">
                            <img class="img-fluid" src={require('../images/about.png')} alt="" />
                            
                        </div>
                    </div>
                    <div class="col-md-7 col-sm-12">
                        <div class="pr-10 res-991-plr-0">
                            
                            <div class="section-title clearfix">
                                <div class="title-header">
                                    <h5>Bay Area Window Glass Company</h5>
                                    <h2 class="title">Window & Glass Repair & Replacement</h2>
                                </div>
                            </div>
                            <p>Bay Area Window Glass offers friendly and cost effective service for all your window and door glass needs, both residential or commercial. From the installation of new home dual pane windows to full commercial store front installation or repair, we can handle any job, big or small. We are proud to have served homes and businesses in the Bay Area for over 40 years with outstanding results.</p>
                            <p class="pt-10 pb-10">If you’re in need of window or patio door replacement, store front window repair or installation, or any other glass repair or replacement, just call Bay Area Window Glass today for great service and the best pricing available. We also offer emergency service for both residential and commercial customers.
                            </p>
                            <p class="pt-10 pb-10"><strong>Give us a call today. We’re here to help solve any glass problem.</strong>
                            </p>
                            <a class="ttm-btn ttm-btn-size-sm ttm-icon-btn-right ttm-btn-color-skincolor btn-inline mb-30" href="#">CALL US NOW!<i class="ti ti-angle-double-right"></i></a>
                            {/* <div class="separator">
                                <div class="sep-line mt_5 mb-35"></div>
                            </div> */}
                            
                            {/* <div class="row">
                                <div class="col-md-4 col-sm-12">
                                    
                                    <div class="featured-icon-box style2 left-icon">
                                        <div class="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-md"> 
                                            <i class="ti ti-timer"></i>
                                        </div>
                                        <div class="featured-content">
                                            <div class="featured-title">
                                                <h5>Ontime at Services</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-12">
                                    
                                    <div class="featured-icon-box style2 left-icon">
                                        <div class="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-md"> 
                                            <i class="ti ti-wand"></i>
                                        </div>
                                        <div class="featured-content">
                                            <div class="featured-title">
                                                <h5>24/7 Services</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-12">
                                    
                                    <div class="featured-icon-box style2 left-icon">
                                        <div class="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-md"> 
                                            <i class="ti ti-medall"></i>
                                        </div>
                                        <div class="featured-content">
                                            <div class="featured-title">
                                                <h5>Verified Professionals</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
    </Section>
  );
}

export default AboutUs;
