import React from "react";

function NavbarContainer(props) {
  return (
    <header id="masthead" className="header ttm-header-style-classic">
        <div className="ttm-topbar-wrapper ttm-bgcolor-darkgrey ttm-textcolor-white clearfix">
            <div className="container">
                <div className="ttm-topbar-content">
                    <ul className="top-contact ttm-highlight-left text-left">
                        <li><a href="tel:8446003960"><i className="fa fa-phone"></i><strong>Toll Free Number:</strong> <span className="tel-no">1-844-600-3960</span></a></li>
                    </ul>
                    <div className="topbar-right text-right">
                        <ul className="top-contact">
                            <li><i className="fa fa-envelope-o"></i><strong>Email: </strong><a href="mailto:sales@roxyglasscompany.com">sales@roxyglasscompanyca.com</a></li>
                        </ul>
                        <div className="ttm-social-links-wrapper list-inline">
                            <div className="lic-number">
                                <p>LIC #1001561</p>
                            </div>
                            {/* <ul className="social-icons">
                                <li><a href="#"><i className="fa fa-facebook"></i></a>
                                </li>
                                <li><a href="#"><i className="fa fa-twitter"></i></a>
                                </li>
                                <li><a href="#"><i className="fa fa-flickr"></i></a>
                                </li>
                                <li><a href="#"><i className="fa fa-linkedin"></i></a>
                                </li>
                            </ul> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="ttm-header-wrap">
            <div id="ttm-stickable-header-w" className="ttm-stickable-header-w clearfix">
                <div id="site-header-menu" className="site-header-menu">
                    <div className="site-header-menu-inner ttm-stickable-header">
                        <div className="container">
                            <div className="site-branding">
                                <a className="home-link" href="/" title="Boldman" rel="home">
                                    <img id="logo-img" className="img-center" src={require('../images/logo-dark.png')} alt="logo-img" />
                                </a>
                            </div>
                            <div id="site-navigation" className="site-navigation">
                                <div className="header-btn">
                                    <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-border ttm-btn-color-black" href="#get-a-quote">REQUEST FREE QUOTE</a>
                                </div>
                                <div className="ttm-menu-toggle">
                                    <input type="checkbox" id="menu-toggle-form" />
                                    <label for="menu-toggle-form" className="ttm-menu-toggle-block">
                                        <span className="toggle-block toggle-blocks-1"></span>
                                        <span className="toggle-block toggle-blocks-2"></span>
                                        <span className="toggle-block toggle-blocks-3"></span>
                                    </label>
                                </div>
                                <nav id="menu"
                                  className={
                                    "menu" +
                                    (props.color ? ` is-${props.color}` : "") +
                                    (props.spaced ? " is-spaced" : "")
                                  }
                                >
                                  {props.children}
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
  );
}

export default NavbarContainer;
