import React from "react";
import Section from "./Section";


function OurProcess(props) {
  return (
    <Section id="process" className="ttm-row processbox-section break-991-colum clearfix" color={props.color} size={props.size}>
        <div class="container">
                
                <div class="row">
                    <div class="col-md-2"></div>
                    <div class="col-md-8">
                        
                        <div class="section-title with-desc title-style-center_text clearfix">
                            <div class="title-header">
                                <h5>HOW WE WORK</h5>
                                <h2 class="title">Our Process</h2>
                            </div>
                            <div class="title-desc">Our team has been serving the greater Bay Area for over 40 years, including over 3 years through Roxy Glass.</div>
                        </div>
                    </div>
                    <div class="col-md-2"></div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="ttm-processbox-wrapper">
                            <div class="ttm-processbox">
                                <div class="ttm-box-image">
                                    <img class="img-fluid" src={require('../images/step-one.jpg')} alt="step-one" title="step-one" />
                                    <div class="process-num">
                                        <span class="number">01</span>
                                    </div>
                                </div>
                                <div class="featured-content">
                                    <div class="featured-title"><h5>Schedule your Appointment</h5></div>
                                    {/* <div class="ttm-box-description">He found himself transformed in his bed into a horrible vermin.</div> */}
                                </div>
                            </div>
                            <div class="ttm-processbox">
                                <div class="ttm-box-image">
                                    <img class="img-fluid" src={require('../images/step-two.jpg')} alt="step-two" title="step-two" />
                                    <div class="process-num">
                                        <span class="number">02</span>
                                    </div>
                                </div>
                                <div class="featured-content">
                                    <div class="featured-title"><h5>Get Professional Advices</h5></div>
                                    {/* <div class="ttm-box-description">He found himself transformed in his bed into a horrible vermin.</div> */}
                                </div>
                            </div>
                            <div class="ttm-processbox">
                                <div class="ttm-box-image">
                                    <img class="img-fluid" src={require('../images/step-three.jpg')} alt="step-three" title="step-three" />
                                    <div class="process-num">
                                        <span class="number">03</span>
                                    </div>
                                </div>
                                <div class="featured-content">
                                    <div class="featured-title"><h5>Meet Your Glass Expert</h5></div>
                                    {/* <div class="ttm-box-description">He found himself transformed in his bed into a horrible vermin.</div> */}
                                </div>
                            </div>
                            <div class="ttm-processbox">
                                <div class="ttm-box-image">
                                    <img class="img-fluid" src={require('../images/step-four.jpg')} alt="step-four" title="step-four" />
                                    <div class="process-num">
                                        <span class="number">04</span>
                                    </div>
                                </div>
                                <div class="featured-content">
                                    <div class="featured-title"><h5>Get a Best Our Services At Door</h5></div>
                                    {/* <div class="ttm-box-description">He found himself transformed in his bed into a horrible vermin.</div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </Section>
  );
}

export default OurProcess;
